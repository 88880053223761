import { DEVICE } from '~/constants/general';

/**
 * A composable for determines the type of device based on whether it's mobile, a tablet, or a desktop.
 *
 * @returns {'MOBILE' | 'TABLET' | 'DESKTOP'} The type of device.
 */
export const useDeviceType = () => {
  const { isMobile } = useDevice();

  // Check if the device has a touchscreen
  const isTouchScreen =  import.meta.client && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

  if (isMobile) {
    return DEVICE.mobile;
  } else if (isTouchScreen) {
    return DEVICE.tablet;
  } else {
    return DEVICE.desktop;
  }
};